@font-face {
    font-family: 'SignPainter-HouseScript';
    src: url('SignPainter-HouseScript.eot');
    src: url('SignPainter-HouseScript.eot?#iefix') format('embedded-opentype'),
        url('SignPainter-HouseScript.woff2') format('woff2'),
        url('SignPainter-HouseScript.woff') format('woff'),
        url('SignPainter-HouseScript.ttf') format('truetype'),
        url('SignPainter-HouseScript.svg#SignPainter-HouseScript') format('svg');
    font-weight: normal;
    font-style: normal;
}

