@tailwind base;
@tailwind components;
@tailwind utilities;
 


@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;
 
    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;
 
    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;
 
    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;
 
    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;
 
    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;
 
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 222.2 84% 4.9%;
 
    --radius: 0.5rem;

    --sidebar-background: 0 0% 98%;
    --sidebar-foreground: 240 5.3% 26.1%;
    --sidebar-primary: 240 5.9% 10%;
    --sidebar-primary-foreground: 0 0% 98%;
    --sidebar-accent: 240 4.8% 95.9%;
    --sidebar-accent-foreground: 240 5.9% 10%;
    --sidebar-border: 220 13% 91%;
    --sidebar-ring: 217.2 91.2% 59.8%;
  }
 
  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;
 
    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;
 
    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;
 
    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 11.2%;
 
    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;
 
    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;
 
    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;
 
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;
 
    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --ring: 212.7 26.8% 83.9%;

    --sidebar-background: 240 5.9% 10%;
    --sidebar-foreground: 240 4.8% 95.9%;
    --sidebar-primary: 224.3 76.3% 48%;
    --sidebar-primary-foreground: 0 0% 100%;
    --sidebar-accent: 240 3.7% 15.9%;
    --sidebar-accent-foreground: 240 4.8% 95.9%;
    --sidebar-border: 240 3.7% 15.9%;
    --sidebar-ring: 217.2 91.2% 59.8%;
  }
}
 
@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply text-foreground;
  }
  html {
    background: rgba(31, 62, 60, 0.05);
  }
}


body {
  background-color: #ffffff00;
}

.badge-soft-primary {
  color: #556ee6;
  background-color: rgba(85, 110, 230, 0.18); }
  .badge-soft-primary[href]:hover, .badge-soft-primary[href]:focus {
    color: #556ee6;
    text-decoration: none;
    background-color: rgba(85, 110, 230, 0.4); }

.badge-soft-secondary {
  color: #74788d;
  background-color: rgba(116, 120, 141, 0.18); }
  .badge-soft-secondary[href]:hover, .badge-soft-secondary[href]:focus {
    color: #74788d;
    text-decoration: none;
    background-color: rgba(116, 120, 141, 0.4); }

.badge-soft-success {
  color: #34c38f;
  background-color: rgba(52, 195, 143, 0.18) !important; }
  .badge-soft-success[href]:hover, .badge-soft-success[href]:focus {
    color: #34c38f;
    text-decoration: none;
    background-color: rgba(52, 195, 143, 0.4); }

.badge-soft-info {
  color: #50a5f1;
  background-color: rgba(80, 165, 241, 0.18); }
  .badge-soft-info[href]:hover, .badge-soft-info[href]:focus {
    color: #50a5f1;
    text-decoration: none;
    background-color: rgba(80, 165, 241, 0.4); }

.badge-soft-warning {
  color: #f1b44c;
  background-color: rgba(241, 180, 76, 0.18); }
  .badge-soft-warning[href]:hover, .badge-soft-warning[href]:focus {
    color: #f1b44c;
    text-decoration: none;
    background-color: rgba(241, 180, 76, 0.4); }

.badge-soft-danger {
  color: #f46a6a;
  background-color: rgba(244, 106, 106, 0.18); }
  .badge-soft-danger[href]:hover, .badge-soft-danger[href]:focus {
    color: #f46a6a;
    text-decoration: none;
    background-color: rgba(244, 106, 106, 0.4); }

.badge-soft-light {
  color: #eff2f7;
  background-color: rgba(239, 242, 247, 0.18); }
  .badge-soft-light[href]:hover, .badge-soft-light[href]:focus {
    color: #eff2f7;
    text-decoration: none;
    background-color: rgba(239, 242, 247, 0.4); }

.badge-soft-dark {
  color: #343a40;
  background-color: rgba(52, 58, 64, 0.18); }
  .badge-soft-dark[href]:hover, .badge-soft-dark[href]:focus {
    color: #343a40;
    text-decoration: none;
    background-color: rgba(52, 58, 64, 0.4); }


/* Note temporary override collapse class from bootstrap */
.collapse:not(.show) {
  visibility: collapse !important;
}

.collapse.show {
  visibility: visible !important;
}

.badge-soft-pink {
  color: #e83e8c;
  background-color: rgba(232, 62, 140, 0.18); }
  .badge-soft-pink[href]:hover, .badge-soft-pink[href]:focus {
    color: #e83e8c;
    text-decoration: none;
    background-color: rgba(232, 62, 140, 0.4); }


.collapse:not(.show) {
  visibility: collapse !important;
}

.collapse.show {
  visibility: visible !important;
}

.w-full-minus-250px {
  width: calc(100% - 250px);
}
