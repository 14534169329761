.placeholder {
  cursor: progress; 
  background-image: linear-gradient(90deg, #ddd 0px, #e8e8e8 40px, #ddd 80px) ;
  background-size: 600px;
  animation: shine-lines 2.5s infinite linear alternate;
  min-height: 1em;
  border-radius: 7px;
  max-width:128px;
}

@keyframes shine-lines {
  0% {
    background-position: -50%;
  }
  100%{
    background-position: 50%;
  }
}