@font-face {
    font-family: "Objectivity";
    font-display: auto;
    src: url("./objectivity-regular.otf");
}
@font-face {
    font-family: "Objectivity";
    font-display: auto;
    src: url("./objectivity-bold.otf");
    font-weight: bold;
}
@font-face {
    font-family: "Objectivity";
    font-display: auto;
    src: url("./objectivity-light.otf");
    font-weight: lighter;
}
@font-face {
    font-family: "Objectivity";
    font-display: auto;
    src: url("./objectivity-medium.otf");
    font-weight: 500;
}

@font-face {
    font-family: 'Bourbon Grotesque';
    src: url('./BourbonGrotesque-Regular.otf') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'HouseSlant';
    src: url('./HouseSlant-Regular.otf') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Machineat';
    src: url('./Machineat-Regular.otf') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'zhcasual';
    src: url('./zh-script.otf') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'FontName';
    src: url('./Machineat-Bold.eot'); /* IE9+ Compat Modes */
    src: url('./Machineat-Bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./Machineat-Bold.woff') format('woff'), /* Modern browsers */
         url('./Machineat-Bold.ttf')  format('truetype'), /* Safari, Android, iOS */
         url('./Machineat-Bold.svg#FontName') format('svg'); /* Legacy iOS */
}

@font-face {
    font-family:"minion-pro";
    src:url("https://use.typekit.net/af/acb0f1/0000000000000000000151d7/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("woff2"),
        url("https://use.typekit.net/af/acb0f1/0000000000000000000151d7/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("woff"),
        url("https://use.typekit.net/af/acb0f1/0000000000000000000151d7/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("opentype");
    font-display:auto;font-style:normal;font-weight:600;
}

body, html {
    width: 100vw;
    margin: 0;
    font-family: "Objectivity";
}