@font-face {
    font-family: 'TitlingGothicFB RegularExtd';
    src: url('TitlingGothicFB-BoldExtended.eot');
    src: url('TitlingGothicFB-BoldExtended.eot?#iefix') format('embedded-opentype'),
        url('TitlingGothicFB-BoldExtended.woff2') format('woff2'),
        url('TitlingGothicFB-BoldExtended.woff') format('woff'),
        url('TitlingGothicFB-BoldExtended.ttf') format('truetype'),
        url('TitlingGothicFB-BoldExtended.svg#TitlingGothicFB-BoldExtended') format('svg');
    font-weight: bold;
    font-style: normal;
}

