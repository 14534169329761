//
// avatar.scss
//

.avatar-xs {
  height: 2rem;
  width: 2rem;
}

.avatar-sm {
  height: 3rem;
  width: 3rem;
}

.avatar-md {
  height: 4.5rem;
  width: 4.5rem;
}

.avatar-lg {
  height: 6rem;
  width: 6rem;
}

.avatar-xl {
  height: 7.5rem;
  width: 7.5rem;
}

.avatar-title {
  align-items: center;
  background-color: $primary;
  color: $white;
  display: flex;
  font-weight: $font-weight-medium;
  height: 100%;
  justify-content: center;
  width: 100%;
}

.avatar-xsi {
  height: auto;
  width: auto;
  text-align: left;
  margin: 0px 0px 10px 0px!important;
}

.avatar-xsi img {
  margin-right: 10px;
}

.avatar-xsi img:last-child{
  margin-right: 0px;
}


.custome-input .form-control.custom-file {
  max-width: 68px;
  margin: 0px 2px;
}

.custome-input .form-control.custom-file label.custom-file-label {
    overflow: hidden;
    width: 68px;
}