.Script {
  font-family: SignPainter-HouseScript;
}

.cursive-bold {
  font-family: 'Machineat';
  font-weight: bold;
}

.Block {
  font-family: 'Bourbon Grotesque';
}

.Casual {
  font-family: 'HouseSlant';
  letter-spacing: 2px;
}

.Wide {
  font-family: 'TitlingGothicFB RegularExtd';
  font-weight: bold;
  font-style: normal;
  color: transparent;
  /* text-shadow: -2px -2px 0 #BC2C55, 2px -2px 0 #BC2C55, -2px 2px 0 #BC2C55, 2px 2px 0 #BC2C55; */
  -webkit-text-stroke: 1px #118AA4;
  -webkit-text-fill-color: transparent;
  padding-top: 6px;
}

.Serif{
  font-family: minion-pro, serif;
  font-weight: 400;
  font-style: normal;
}


.label-big-check {
position: relative;
display: block;
margin: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 12px;
  color: white;
}
.label-big-check input {
display: none;
}
.label-big-check .check-title {
  margin-top: 4px;
font-size: 1.2em;
font-weight: 400;
cursor: pointer;
}

.label-big-check input[type="checkbox"] + .check-title .open {
  display: inline-block;
}
.label-big-check input[type="checkbox"] + .check-title .closed {
  display: none;
}
.label-big-check input[type="checkbox"]:checked + .check-title .open {
  display: none;
}
.label-big-check input[type="checkbox"]:checked + .check-title .closed {
  display: inline-block;
}


@media only screen and  (min-width: 1025px) {
  .min-full {
      height: calc(100vh - 56px);
  }
}

@media only screen and  (max-width: 1025px) {
  .landing-search-right {
      width: 100%;
      margin-top: -50px;
      padding-bottom: 8px;
  }
  .landing-search-left {
      width: 100%;
      margin: 0 -100px;
      margin-top: -60px;
      margin-bottom: -20px;
      width: calc(100% + 200px);
  }
  .landing-search-container {
      flex-direction: column;
  }
}



.ZH-SER {
  font-weight: 300;
  font-family: 'Noto Serif SC', serif;
  font-size: 0.7em;
}
.ZH-SAN {
  font-weight: 300;
  font-family: 'Noto Sans SC', sans-serif;
  font-size: 0.7em;
}
.ZH-BLOCK {
  font-weight: 300;
  font-family: 'Ma Shan Zheng', cursive;
  font-size: 0.7em;
}
.ZH-CASUAL {
  font-weight: 300;
  font-family: 'zhcasual', sans-serif;
  font-size: 0.7em;
}
.ZH-SCRIPT {
  font-weight: 300;
  font-family: 'Zhi Mang Xing', cursive;
  font-size: 0.7em;
}

label {
  margin-top:0;
}



@font-face {
  font-family: 'Bourbon Grotesque';
  src: url('https://cdn.july.com/personalisation/fonts/BourbonGrotesque-Regular.otf') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'HouseSlant';
  src: url('https://cdn.july.com/personalisation/fonts/HouseSlant-Regular.otf') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Machineat';
  src: url('https://cdn.july.com/personalisation/fonts/Machineat-Regular.otf') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'zhcasual';
  src: url('https://cdn.july.com/personalisation/fonts/zh-script.otf') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'FontName';
  src: url('https://cdn.july.com/personalisation/fonts/Machineat-Bold.eot'); /* IE9+ Compat Modes */
  src: url('https://cdn.july.com/personalisation/fonts/Machineat-Bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('https://cdn.july.com/personalisation/fonts/Machineat-Bold.woff') format('woff'), /* Modern browsers */
       url('https://cdn.july.com/personalisation/fonts/Machineat-Bold.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('https://cdn.july.com/personalisation/fonts/Machineat-Bold.svg#FontName') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family:"minion-pro";
  src:url("https://use.typekit.net/af/acb0f1/0000000000000000000151d7/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("woff2"),
      url("https://use.typekit.net/af/acb0f1/0000000000000000000151d7/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("woff"),
      url("https://use.typekit.net/af/acb0f1/0000000000000000000151d7/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("opentype");
  font-style:normal;font-weight:400;
}

@font-face {
  font-family: 'TitlingGothicFB RegularExtd';
  src: url('https://cdn.july.com/personalisation/fonts/TitlingGothicFB-BoldExtended.eot');
  src: url('https://cdn.july.com/personalisation/fonts/TitlingGothicFB-BoldExtended.eot?#iefix') format('embedded-opentype'),
      url('https://cdn.july.com/personalisation/fonts/TitlingGothicFB-BoldExtended.woff2') format('woff2'),
      url('https://cdn.july.com/personalisation/fonts/TitlingGothicFB-BoldExtended.woff') format('woff'),
      url('https://cdn.july.com/personalisation/fonts/TitlingGothicFB-BoldExtended.ttf') format('truetype'),
      url('https://cdn.july.com/personalisation/fonts/TitlingGothicFB-BoldExtended.svg#TitlingGothicFB-BoldExtended') format('svg');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'SignPainter-HouseScript';
  src: url('https://cdn.july.com/personalisation/fonts/SignPainter-HouseScript.eot');
  src: url('https://cdn.july.com/personalisation/fonts/SignPainter-HouseScript.eot?#iefix') format('embedded-opentype'),
      url('https://cdn.july.com/personalisation/fonts/SignPainter-HouseScript.woff2') format('woff2'),
      url('https://cdn.july.com/personalisation/fonts/SignPainter-HouseScript.woff') format('woff'),
      url('https://cdn.july.com/personalisation/fonts/SignPainter-HouseScript.ttf') format('truetype'),
      url('https://cdn.july.com/personalisation/fonts/SignPainter-HouseScript.svg#SignPainter-HouseScript') format('svg');
  font-weight: normal;
  font-style: normal;
}

.serif{
  font-family: 'minion-pro', serif;
  font-weight: 400;
  font-style: normal;
}

.wide {
  font-family: 'TitlingGothicFB RegularExtd';
  font-weight: bold;
  font-style: normal;
  color: transparent;
  /* text-shadow: -2px -2px 0 #BC2C55, 2px -2px 0 #BC2C55, -2px 2px 0 #BC2C55, 2px 2px 0 #BC2C55; */
  -webkit-text-stroke: 1px #757763;
  -webkit-text-fill-color: transparent;
}


.Zh-ser {
  font-weight: 300;
  font-family: 'Noto Serif SC', serif;
  font-size: 0.7em;
}
.Zh-san {
  font-weight: 300;
  font-family: 'Noto Sans SC', sans-serif;
  font-size: 0.7em;
}
.Zh-block {
  font-weight: 300;
  font-family: 'Ma Shan Zheng', cursive;
  font-size: 0.7em;
}
.Zh-casual {
  font-weight: 300;
  font-family: 'zhcasual', sans-serif;
  font-size: 0.7em;
}
.Zh-script {
  font-weight: 300;
  font-family: 'Zhi Mang Xing', cursive;
  font-size: 0.7em;
}

.all-serif{
  font-family: 'Caladea', serif;
  font-size: 0.7em;
}

.all-sans{
  font-family: 'Work Sans', sans-serif;
  font-weight: 600;
  font-size: 0.7em;
}

.All-serif{
  font-family: 'Caladea', serif;
  font-size: 0.7em;
}

.All-sans{
  font-family: 'Work Sans', sans-serif;
  font-weight: 600;
  font-size: 0.7em;
}

.love-letter{
  font-family: "Pinyon Script", serif;
  font-size: 0.7em;
}



@media only screen and (max-width: 1023px) {
  .per-page-text{
      font-size: 0.4em !important;
  }
  .All-serif{
      font-family: 'Caladea', serif;
      font-size: 0.4em;
  }
  
  .All-sans{
      font-family: 'Work Sans', sans-serif;
      font-weight: 600;
      font-size: 0.4em;
  }
  .all-serif{
      font-family: 'Caladea', serif;
      font-size: 0.4em;
      padding-bottom: 0.6em;
  }
  
  .all-sans{
      font-family: 'Work Sans', sans-serif;
      font-weight: 600;
      font-size: 0.4em;
      padding-bottom: 0.6em;
  }
}

div {
  outline: none !important;
}

#app {
margin-top: 40px;
}

#progressInput {
margin: 20px auto;
width: 30%;
}

.circle-background,
.circle-progress {
fill: none;
}

.circle-background {
stroke: #ddd;
}

.circle-progress {
stroke: rgb(31, 62, 60 );
stroke-linecap: round;
stroke-linejoin: round;
}

.circle-text {
font-size: 1.5em;
font-weight: bold;
fill: rgb(31, 62, 60 );
}

.circle-text.playing{
  font-size: 3em;
}

.Script {
  font-family: SignPainter-HouseScript;
}

.cursive-bold {
  font-family: 'Machineat';
  font-weight: bold;
}

.Block {
  font-family: 'Bourbon Grotesque';
}

.Casual {
  font-family: 'HouseSlant';
  letter-spacing: 2px;
}

.Wide {
  font-family: 'TitlingGothicFB RegularExtd';
  font-weight: bold;
  font-style: normal;
  color: transparent;
  /* text-shadow: -2px -2px 0 #BC2C55, 2px -2px 0 #BC2C55, -2px 2px 0 #BC2C55, 2px 2px 0 #BC2C55; */
  -webkit-text-stroke: 1px #118AA4;
  -webkit-text-fill-color: transparent;
  padding-top: 6px;
}

.Serif{
  font-family: minion-pro, serif;
  font-weight: 400;
  font-style: normal;
}

.bold {
  font-family: "noelle_serifregular";
}
.cursive {
  font-family: "monoline_scriptregular";
}
.modern {
  font-family: "zephyrregular";
}

.graphic {
  font-family: 'Raven Hell Black Gothic';
}
.heritage {
  font-family: 'FHA Sign DeVinne ShadeNC';
}
.heritage-back {
  font-family: 'FHA Sign DeVinneNC';
}
.bold-new {
  font-family: 'Dida';
}
.retro-serif {
  font-family: 'Canopee';
}
.decorative {
  font-family: 'Salter';
}
.sans-serif {
  font-family: 'Object Sans';
}
.bubble {
  font-family: 'Salbabida Sans';
}


:root {
  --very-light-pink: 246, 243, 239;
  --terracota: 202, 122, 79;
  --black: 40, 40, 40;
  --silver: 206, 209, 207;
  --white: 256, 256, 256;
  --duck-egg-blue: 222, 226, 226;
  --dark-blue-grey: 31, 62, 60;
  --swiper-theme-color: rgb(31, 62, 60) !important;
  --editions-blue: 60, 77, 82;
  --editions-cream: 246, 243, 239;
  --editions-brown: 162, 128, 109;
  --editions-grey: 151, 151, 151;
  --editions-blue: 90, 113, 112;
  --per-cream: 246, 243, 239;
  --per-mint: 215, 224, 222;
  --landing-per: 95, 155, 141;
  --fathers-blue: 39, 40, 76;
  --lux-blue: 104, 132, 130;
  --dark-pink: 203, 118, 101;

  --boxing-green: 86, 117, 97;
  --week-green: 119, 138, 100;
  --pro-green: 114, 117, 83;

  --light-orange: 215, 158, 1;
  --light-navy: 2, 66, 136;

  --sa: 36, 102, 102;
  --agg: 225, 182, 85;
  --mw: 87, 108, 100;
  --w: 103, 120, 84;
  --btv: 124, 48, 51;
  --gift-guide-green: 131, 151, 94;
}

.App {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  flex-direction: column;
}

.print {
  opacity: 0;
  margin: 0 -32px;
  margin-top: -24px;
}
@media print {
  .screen {
    display: none;
  }
  .print {
    opacity: 1;
  }
}

@page {
  size: auto; /* auto is the initial value */
  margin: 0mm; /* this affects the margin in the printer settings */
}

.PrintText {
  /* width: 100vw; */
  display: flex;
  justify-content: flex-start;
  font-size: 30px !important;
}

.RenderCanvas {
  overflow: hidden;
  background: rgb(123, 109, 0);
}

.PrintCanvas {
  background-color: black;
  width: 100vw;
  height: 100vh;
  padding: 32px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
}

.PrintCanvas:last-child {
  height: 95vh;
}

.BlockLetter {
  margin-left: 0.15em;
}
.LetterContainer {
  text-align: center;
  /* line-height: 184px; */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  position: relative;
}
.LetterWidth {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.LetterContainerPrint {
  height: 75px;
  padding: 4px 0;
  text-align: center;
  padding: 8px;
  font-size: 30px !important;
}
.BlockContainerPrint {
  height: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.BlockContainer {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

table {
  border-collapse: collapse;
  width: 100%;
}

th,
td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

tr:hover {
  background-color: #f5f5f5;
}

.zh-ser {
  font-weight: 600;
  font-family: "Noto Serif SC", serif;
  font-size: 0.5em;
}

.zh-san {
  font-weight: 600;
  font-family: "Noto Sans SC", sans-serif;
  font-size: 0.5em;
}

.zh-block {
  font-weight: 500;
  font-family: "Ma Shan Zheng", cursive;
  font-size: 0.5em;
}

.zh-script {
  font-weight: 500;
  font-family: "Zhi Mang Xing", cursive;
  font-size: 0.5em;
}

.zh-casual {
  font-weight: 400;
  font-family: "zhcasual", sans-serif;
  font-size: 0.5em;
}

.ZH-SER {
  font-weight: 600;
  font-family: "Noto Serif SC", serif;
  font-size: 0.5em;
}

.ZH-SAN {
  font-weight: 600;
  font-family: "Noto Sans SC", sans-serif;
  font-size: 0.5em;
}

.ZH-BLOVK {
  font-weight: 500;
  font-family: "Ma Shan Zheng", cursive;
  font-size: 0.5em;
}

.ZH-SCRIPT {
  font-weight: 500;
  font-family: "Zhi Mang Xing", cursive;
  font-size: 0.5em;
}

.ZH-CASUAL {
  font-weight: 400;
  font-family: "zhcasual", sans-serif;
  font-size: 0.5em;
}

.LetterWidth:first-child {
  margin-left: 0;
}

.casual {
  font-family: HouseSlant;
}

.all-serif {
  font-family: "Caladea", serif;
}

.all-sans {
  font-family: "Work Sans", sans-serif;
  font-weight: 600;
}

.All-serif {
  font-family: "Caladea", serif;
}

.All-sans {
  font-family: "Work Sans", sans-serif;
  font-weight: 600;
}

.college {
  font-family: "KVC-Bucktooth Block";
  -webkit-text-stroke: 0.05em #118aa4;
}

.painted {
  font-family: "Total Rage SVG";
}

.retro {
  font-family: "Bright";
}

.script {
  font-family: "SignPainter-HouseScript";
}

.serif {
  font-family: "Minion Pro";
  font-weight: 500;
}

.signature {
  font-family: "Karstar signature";
}

.wide {
  font-family: "TitlingGothicFB RegularExtd";
  font-weight: bold;
  font-style: normal;
  color: rgba(123, 109, 0, 0);
  font-size: 50px;
  /* text-shadow: -2px -2px 0 #BC2C55, 2px -2px 0 #BC2C55, -2px 2px 0 #BC2C55, 2px 2px 0 #BC2C55; */
  -webkit-text-stroke: 0.03em #118aa4;
  -webkit-text-fill-color: rgba(123, 109, 0, 0);
  letter-spacing: 5px;
}